import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import Typography from '@mui/material/Typography';
import { useLocalStorage } from "../useLocalStorage";
import { firestore } from "../firebase";
import { collection, getDocs, query, orderBy, limit, startAfter, getCountFromServer, where } from "firebase/firestore";
import { Table, Button, Input } from "antd";
import { SearchOutlined, FormOutlined, FileExcelOutlined } from "@ant-design/icons";
import { makeStyles } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import './AllUsersPanel.css';
import Box from '@mui/material/Box';
import style from "styled-components";
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { useUserAuth } from "../context/UserAuthContext";
import Loading from "./Loading";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useUsersStore } from "../stores/users";
import { format } from "date-fns";

const StyledInput = styled(Input)`
  background-color: #141414 !important;
  border: 1px solid #1C1C1C !important;
  color: #fff !important;

  .ant-input {
    background-color: #141414 !important;
    border: none !important;
    color: #fff !important;
  }

  .ant-input-clear-icon {
    color: #fff !important;
  }
`;

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "14px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff"
  }
})

const ContainerGeneral = style.div`
    padding: 1em 5em 2em;
    background: rgb(14, 14, 14);

@media screen and (min-width: 0px) and (max-width: 1000px) {
        padding: 1em;
}
`;

const CssTable = styled(Table)({
  '& .ant-table': {
    borderBottom: '1px solid #1F1F1F',
    borderRadius: "13px",
    background: "rgb(20, 20, 20)",
    fontFamily: "Outfit",
    fontSize: "14px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff"
  },
  '& .ant-table-thead > tr > th': {
    fontFamily: "Outfit",
    fontSize: "16px",
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#fff",
    background: "#1A1A1A",
    borderBottom: "1px solid #1F1F1F"
  },
  '& .ant-table-container table > thead > tr:first-child th:first-child': {
    borderTopLeftRadius: "13px",
  },
  '& .ant-table-container table > thead > tr:first-child th:last-child': {
    borderTopRightRadius: "13px",
  },
  '& .ant-table-tbody > tr > td': {
    borderBottom: "1px solid #1F1F1F"
  },
  '& .ant-table-tbody > tr.ant-table-row-hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover': {
    background: "#1A1A1A",
  },
  '& .ant-pagination-item-active': {
    background: "rgb(20, 20, 20)",
    borderColor: "#000",
  },

  '& .ant-pagination-item-active a': {
    color: "#fff"
  },

  '& .ant-pagination-item a': {
    color: "#fff"
  },

  '& .ant-pagination-item': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#1F1F1F",

  },

  '& .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#000",
  },

  '& .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link': {
    borderRadius: "8px",
    background: "#1A1A1A",
    borderColor: "#000",
  },

});

const AllUsersPanel = () => {
  const usersCollectionRef = collection(firestore, "Users");
  // const [allUsers, setAllUsers] = useState([]);
  const [, setUidUserDetail] = useLocalStorage("uidUserDetail", "");
  const navigate = useNavigate();
  const classes = useStyles();
  const { loadSpin, loadingComponent } = useUserAuth();

  const users = useUsersStore((state) => state.users);
  const pagination = useUsersStore((state) => state.pagination);
  const currentPage = useUsersStore((state) => state.currentPage);
  const listSize = useUsersStore((state) => state.listSize);
  const searchByName = useUsersStore((state) => state.searchByName);
  const searchByEmail = useUsersStore((state) => state.searchByEmail);
  const setState = useUsersStore((state) => state.setState);

  const [searchName, setSearchName] = useState(searchByName);
  const [searchEmail, setSearchEmail] = useState(searchByEmail);

  const handleOnExport = async () => {
    const usersExport = await getAllUsers();
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(usersExport);

    utils.book_append_sheet(wb, ws, "usuarios");

    writeFile(wb, "usuarios.xlsx");
  };

  const handleMoreDetail = (userUid) => {
    setUidUserDetail(userUid);
    navigate(`/userDetail/${userUid}`);
  }

  const getAllUsers = async () => {
    loadSpin(true);
    try {
      const q = query(usersCollectionRef, orderBy("userName", "asc"));
      const querySnapshot = await getDocs(q);

      const items = [];
      querySnapshot.forEach((doc) => {
        const item = doc.data();
        items.push({
          ...item,
          id: doc.id,
          deposits: item?.deposits || 0,
          depositsTotal: item?.depositsTotal || 0,
          ganancias: item.ganancias?.toFixed(2) || "0.00",
          perdidas: item.perdidas?.toFixed(2) || "0.00",
          balance: item.balance?.toFixed(2) || "0.00",
          currentBalance: item.balance ?? 0,
        });
      });

      return items;
    } catch (error) {
      console.error("Error al obtener usuarios:", error);
    } finally {
      loadSpin(false);
    }
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Apuestas',
      dataIndex: 'totalBets',
      key: 'totalBets',
    },
    {
      title: 'Total ingresado',
      dataIndex: 'depositsTotal',
      key: 'depositsTotal',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0 }}>${record.depositsTotal}</p>
          </>
        );
      }
    },
    {
      title: 'Ganancias',
      dataIndex: 'ganancias',
      key: 'ganancias',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0, color: '#50AF48' }}>${record.ganancias}</p>
          </>
        );
      }
    },
    {
      title: 'Perdidas',
      dataIndex: 'perdidas',
      key: 'perdidas',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0, color: '#DF2451' }}>${record.perdidas}</p>
          </>
        );
      }
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0, color: '#603B9A' }}>${record.balance}</p>
          </>
        );
      }
    },
    {
      title: "Ver más",
      key: "action",
      render: (text, record) => (
        <>
          <Button
            onClick={() => handleMoreDetail(record.id)}
            type="link"
            icon={<FormOutlined />}
          >
          </Button>
        </>
      ),
    },
  ];

  const getUsers = async (page) => {
    const { pageSize, pageStarts } = pagination;
    const startAfterPage = pageStarts[page - 1];

    let queryUsers = query(
      usersCollectionRef,
      orderBy("userName", "asc"),
      limit(pageSize)
    );

    if (searchByName.length > 3) {
      queryUsers = query(
        queryUsers,
        where("userName", ">=", searchByName),
        where("userName", "<=", searchByName + "\uf8ff")
      );
    }

    if (searchByEmail.length > 3) {
      queryUsers = query(
        queryUsers,
        where("email", ">=", searchByEmail),
        where("email", "<=", searchByEmail + "\uf8ff")
      );
    }
    
    if (startAfterPage) {
      queryUsers = query(queryUsers, startAfter(startAfterPage));
    }

    const snapshot = await getDocs(queryUsers);
    const docs = snapshot.docs;
    const newItems = docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      deposits: doc.data()?.deposits || 0,
      depositsTotal: doc.data()?.depositsTotal || 0,
      ganancias: doc.data().ganancias?.toFixed(2) || "0.00",
      perdidas: doc.data().perdidas?.toFixed(2) || "0.00",
      balance: doc.data().balance?.toFixed(2) || "0.00",
      currentBalance: doc.data().balance ?? 0,
    }));
    
    setState("users", newItems);

    if (page === pageStarts.length && docs.length === pageSize) {
      setState("pagination", { ...pagination, pageStarts: [...pageStarts, docs[docs.length - 1]] });
    }
    loadSpin(false);
  };

  const getUsersCount = async () => {
    let queryUsers = query(usersCollectionRef);

    if (searchByName.length > 0) {
      queryUsers = query(
        queryUsers,
        where("userName", ">=", searchByName),
        where("userName", "<=", searchByName + "\uf8ff")
      );
    }

    if (searchByEmail.length > 0) {
      queryUsers = query(
        queryUsers,
        where("email", ">=", searchByEmail),
        where("email", "<=", searchByEmail + "\uf8ff")
      );
    }

    const snapshotCount = await getCountFromServer(queryUsers);
    setState("listSize", snapshotCount.data().count);
    setState("currentPage", 1);
  };

  const handlePageChange = (newPage) => {
    setState("currentPage", newPage);
    setState("pagination", { ...pagination, page: newPage });
    getUsers(newPage);
  };

  const getUserData = async () => {
    try {
      loadSpin(true);
  
      const usersSnapshot = await getDocs(collection(firestore, "Users"));
      const users = [];
  
      const processBetsOrEvents = (snapshot) =>
        snapshot.docs
          .map((doc) => doc.data())
          .filter((item) => item.status !== "rechazada");

      const calculateStats = (items, dateField, amountField) => {
        if (!items.length) return { total: 0, totalAmount: 0, firstDate: null, firstAmount: 0, lastDate: null, lastAmount: 0 };
  
        const total = items.length;
        const totalAmount = items.reduce((sum, item) => sum + (item[amountField] || 0), 0);
  
        const sortedItems = items
          .filter((item) => item[dateField])
          .sort((a, b) => new Date(a[dateField].toDate()) - new Date(b[dateField].toDate()));
  
        const firstItem = sortedItems[0];
        const lastItem = sortedItems[sortedItems.length - 1];
  
        return {
          total,
          totalAmount,
          firstDate: firstItem ? format(new Date(firstItem[dateField].toDate()), "yyyy-MM-dd") : null,
          firstAmount: firstItem ? firstItem[amountField] : 0,
          lastDate: lastItem ? format(new Date(lastItem[dateField].toDate()), "yyyy-MM-dd") : null,
          lastAmount: lastItem ? lastItem[amountField] : 0,
        };
      };
  
      for (const userDoc of usersSnapshot.docs) {
        const userData = userDoc.data();
        const userId = userDoc.id;
  
        const [betsSnapshot, eventsSnapshot, depositsSnapshot] = await Promise.all([
          getDocs(query(collection(firestore, "Bets"), where("uidUser", "==", userId))),
          getDocs(query(collection(firestore, "EventsBets"), where("uidUser", "==", userId))),
          getDocs(query(collection(firestore, "Deposits"), where("uid", "==", userId), where("status", "==", "approved"))),
        ]);
  
        const bets = processBetsOrEvents(betsSnapshot);
        const events = processBetsOrEvents(eventsSnapshot);
        const deposits = depositsSnapshot.docs.map((doc) => doc.data());
  
        const combinedBets = [...bets, ...events];
  
        const betStats = calculateStats(combinedBets, "date", "bet");
        const depositStats = calculateStats(deposits, "depositDate", "amount");
  
        users.push({
          nombre: userData.userName,
          email: userData.email,
          celular: userData.phoneNumber,
          fechaNacimiento: userData.birthDate,
          fechaRegistro: format(new Date(userData.joinDay.toDate()), "yyyy-MM-dd"),
          fechaPrimerDeposito: depositStats.firstDate,
          montoPrimerDeposito: depositStats.firstAmount,
          fechaUltimoDeposito: depositStats.lastDate,
          montoUltimoDeposito: depositStats.lastAmount,
          totalDepositos: depositStats.total,
          montoTotalDepositos: depositStats.totalAmount,
          fechaPrimerApuesta: betStats.firstDate,
          montoPrimerApuesta: betStats.firstAmount,
          fechaUltimaApuesta: betStats.lastDate,
          montoUltimaApuesta: betStats.lastAmount,
          totalApuestas: betStats.total,
          montoTotalApuestas: betStats.totalAmount,
        });
      }
  
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(users);
      utils.book_append_sheet(wb, ws, "usuarios");
  
      writeFile(wb, "usuarios.xlsx");
    } catch (error) {
      console.error("Error al obtener datos de usuarios:", error);
    } finally {
      loadSpin(false);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setState("searchByName", searchName);
      setState("searchByEmail", searchEmail);
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, [searchName, searchEmail, setState]);

  useEffect(() => {
    getUsers(1);
    getUsersCount();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchByName, searchByEmail]);


  return (

    <ContainerGeneral component="main" maxWidth="100%" style={{ background: "rgb(14, 14, 14)" }}>
      {loadingComponent &&
        <Loading />
      }
      {!loadingComponent &&
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >

          <div className="container-users-actions">
            <div style={{ flex: 2 }}>
              <Typography component="h1" variant="h4" className={classes.typography} style={{ fontSize: "2.125rem", textAlign: "left" }}>
                Usuarios
              </Typography>
            </div>
            <FileExcelOutlined
              className="btn-export-xlsx"
              onClick={handleOnExport}>
            </FileExcelOutlined>

            <Button
              className="btn-export-xlsx"
              onClick={getUserData}
              style={{ background: 'none', border: 'none', color: "white", fontSize: "20px" }}
              icon={<FileExcelOutlined style={{ color: "white" }} />}
            >
              Exportar datos
            </Button>
          </div>
          <div style={{ marginBottom: 16, display: 'flex', gap: '8px' }}>
            <StyledInput
              placeholder="Buscar por nombre"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              prefix={<SearchOutlined />}
              allowClear
            />
            <StyledInput
              placeholder="Buscar por correo"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
              prefix={<SearchOutlined />}
              allowClear
            />
          </div>
            <>
              <TableContainer component={Paper} style={{ borderRadius: 20, background: "rgb(14, 14, 14)", }}>
                <CssTable
                  dataSource={users}
                  columns={columns}
                  rowKey="id"
                  scroll={{ x: '70vw' }}
                  style={{ background: "rgb(14, 14, 14)" }}
                  pagination={false}
                />
              </TableContainer>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
                <Button
                  disabled={currentPage === 1}
                  style={{ background: 'none', border: 'none' }}
                  onClick={() => handlePageChange(currentPage - 1)}>
                  <LeftOutlined style={{ color: "white" }} />
                </Button>
                <p style={{color: 'white'}}>{currentPage} de {Math.ceil(listSize / pagination.pageSize)}</p>
                <Button
                  disabled={currentPage === Math.ceil(listSize / pagination.pageSize)}
                  style={{ background: 'none', border: 'none' }}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <RightOutlined style={{ color: "white" }} />
                </Button>
              </div>
            </>
        </Box>
      }
    </ContainerGeneral>
  )

};

export default AllUsersPanel;
